export const FETCH_SUBSCRIPTION_DATA_LOADING =
  "FETCH_SUBSCRIPTION_DATA_LOADING";
export const FETCH_SUBSCRIPTION_DATA = "FETCH_SUBSCRIPTION_DATA";
export const FETCH_SUBSCRIPTION_DATA_ERROR = "FETCH_SUBSCRIPTION_DATA_ERROR";

export const GET_SUBSCRIPTION_LOADING = "GET_SUBSCRIPTION_LOADING";
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const REACTIVE_SUBSCRIPTION_LOADING = "REACTIVE_SUBSCRIPTION_LOADING";
export const CANCEL_SUBSCRIPTION_LOADING = "CANCEL_SUBSCRIPTION_LOADING";

export const GET_ORDER_DATA_LOADING = "GET_ORDER_DATA_LOADING";
export const GET_ORDER_DATA = "GET_ORDER_DATA";
export const GET_SHIPBOB_ORDER_LOADING = "GET_SHIPBOB_ORDER_LOADING";
export const GET_SHIPBOB_ORDER = "GET_SHIPBOB_ORDER";
export const GET_SHIPMENT_TIME_LINE_LOADING = "GET_SHIPMENT_TIME_LINE_LOADING";
export const GET_SHIPMENT_TIME_LINE_DATA = "GET_SHIPMENT_TIME_LINE_DATA";
