import { combineReducers } from "redux";
import Auth from "./Auth/reducer";
import NewAuth from "../modules/authentication/store/reducer";
import Layout from "./Layout/reducer";
import Login from "../modules/Login/store/reducer";
import Tutorial from "modules/Tutorials/store/reducer";
import Subscription from "modules/billingSubscriptions/store/reducer";
import Contact from "modules/Contact/store/reducer";
import Payment from "modules/paymentMethod/store/reducer";
// import checkout from "modules/old-checkout/store/reducer";
import Team from "modules/Team/store/reducer";
import subscriptionConfirmation from "modules/checkout/store/reducer";

const rootReducer = combineReducers({
  Login,
  Auth,
  NewAuth,
  Layout,
  Tutorial,
  Subscription,
  Contact,
  Payment,
  // checkout,
  Team,
  subscriptionConfirmation,
});

export default rootReducer;
