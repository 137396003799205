import { get } from "services/cookies";
import {
  FETCH_USER,
  FETCH_USER_LOADING,
  LOGOUT,
  MAGIC_LINK_LOADING,
  SET_CHATBOT_VISIBILITY,
  SET_INVITATION_DETAIL,
  SET_IS_AUTH,
  SET_TOKEN,
  VERIFY_ACCOUNT_,
  VERIFY_ACCOUNT_LOADING,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  isAuth: false,
  token: !!get("token") ? get("token") : null,
  fetchUserLoading: false,
  user: null,
  chatBoxVisibility: "minimized",
  verifyAccountLoading: false,
  verifyAccountData: null,
  magicLinkLoading: false,
  invite: null,
};

const authReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: payload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuth: false,
        token: null,
        user: null,
      };
    case SET_CHATBOT_VISIBILITY:
      return { ...state, chatBoxVisibility: payload };
    case FETCH_USER_LOADING:
      return {
        ...state,
        fetchUserLoading: payload,
      };
    case FETCH_USER:
      return {
        ...state,
        user: payload,
      };

    case VERIFY_ACCOUNT_LOADING:
      return {
        ...state,
        verifyAccountLoading: payload,
      };
    case VERIFY_ACCOUNT_:
      return {
        ...state,
        verifyAccountData: payload,
      };
    case MAGIC_LINK_LOADING:
      return {
        ...state,
        magicLinkLoading: payload,
      };
    case SET_INVITATION_DETAIL:
      return {
        invite: payload,
      };
    default:
      return state;
  }
});

export default authReducer;
