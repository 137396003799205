import produce from "immer";
import {
  TEAM_DATA_LOADING,
  TEAM_DATA,
  SET_USER_DATA,
  MEMBERSHIP_DATA_LOADING,
  MEMBERSHIP_DATA,
} from "./actionTypes";

const initialState = {
  teamDataLoading: false,
  teamData: null,
  user: {
    name: null,
    email: null,
  },
  MemberShipLoading: false,
  MemberShipData: null,
};

const teamReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TEAM_DATA_LOADING:
      return {
        ...state,
        teamDataLoading: payload,
      };
    case TEAM_DATA:
      return {
        ...state,
        teamData: payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user: {
          name: payload.name,
          email: payload.email,
        },
      };
    case MEMBERSHIP_DATA_LOADING:
      return {
        ...state,
        MemberShipLoading: payload,
      };
    case MEMBERSHIP_DATA:
      return {
        ...state,
        MemberShipData: payload,
      };
    default:
      return state;
  }
});

export default teamReducer;
