import produce from "immer";
import {
  ADD_CARD_LOADING,
  FETCH_PAYMENT_DATA,
  FETCH_PAYMENT_DATA_ERROR,
  FETCH_PAYMENT_DATA_LOADING,
} from "./actionTypes";

const initialState = {
  paymentDataLoading: false,
  paymentData: null,
  addPaymentLoading: false,
};

const paymentReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PAYMENT_DATA_LOADING:
      return {
        ...state,
        paymentDataLoading: payload,
      };
    case FETCH_PAYMENT_DATA:
      return {
        ...state,
        paymentData: payload,
      };
    case FETCH_PAYMENT_DATA_ERROR:
      return {
        ...state,
        paymentData: payload,
      };
    case ADD_CARD_LOADING:
      return {
        ...state,
        addPaymentLoading: payload,
      };
    default:
      return state;
  }
});

export default paymentReducer;
