import produce from "immer";
import {
  CANCEL_SUBSCRIPTION_LOADING,
  FETCH_SUBSCRIPTION_DATA,
  FETCH_SUBSCRIPTION_DATA_ERROR,
  FETCH_SUBSCRIPTION_DATA_LOADING,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_LOADING,
  REACTIVE_SUBSCRIPTION_LOADING,
  GET_ORDER_DATA_LOADING,
  GET_ORDER_DATA,
  GET_SHIPBOB_ORDER_LOADING,
  GET_SHIPBOB_ORDER,
  GET_SHIPMENT_TIME_LINE_LOADING,
  GET_SHIPMENT_TIME_LINE_DATA,
} from "./actionTypes";

const initialState = {
  ReactivateSubscriptionLoading: false,
  CancelSubscriptionLoading: false,
  subscription: {
    subscriptionDataLoading: false,
    subscriptionData: null,
  },
  userSubscription: {
    loading: false,
    data: null,
  },
  order: {
    loading: false,
    data: null,
  },
  shipBobOrder: {
    shipBobData: null,
    shipBobLoading: false,
  },
  shipmenTimeLine: {
    timeLineData: null,
    timeLineDataLoading: false,
  },
};

const subscriptionReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SUBSCRIPTION_DATA_LOADING:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          subscriptionDataLoading: payload,
        },
      };
    case FETCH_SUBSCRIPTION_DATA:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          subscriptionData: payload,
        },
      };
    case FETCH_SUBSCRIPTION_DATA_ERROR:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          subscriptionData: payload,
        },
      };
    case GET_SUBSCRIPTION_LOADING:
      return {
        ...state,
        userSubscription: {
          ...state.userSubscription,
          loading: payload,
        },
      };
    case GET_SUBSCRIPTION:
      return {
        ...state,
        userSubscription: {
          ...state.userSubscription,
          data: payload,
        },
      };
    case REACTIVE_SUBSCRIPTION_LOADING:
      return {
        ...state,
        ReactivateSubscriptionLoading: payload,
      };
    case CANCEL_SUBSCRIPTION_LOADING:
      return {
        ...state,
        CancelSubscriptionLoading: payload,
      };
    case GET_ORDER_DATA_LOADING:
      return {
        ...state,
        order: {
          ...state.order,
          loading: payload,
        },
      };
    case GET_ORDER_DATA:
      return {
        ...state,
        order: {
          ...state.order,
          data: payload,
        },
      };
    case GET_SHIPBOB_ORDER_LOADING:
      return {
        ...state,
        shipBobOrder: {
          ...state.shipBobOrder,
          shipBobLoading: payload,
        },
      };
    case GET_SHIPBOB_ORDER:
      return {
        ...state,
        shipBobOrder: {
          ...state.shipBobOrder,
          shipBobData: payload,
        },
      };
    case GET_SHIPMENT_TIME_LINE_LOADING:
      return {
        ...state,
        shipmenTimeLine: {
          ...state.shipmenTimeLine,
          timeLineDataLoading: payload,
        },
      };
    case GET_SHIPMENT_TIME_LINE_DATA:
      return {
        ...state,
        shipmenTimeLine: {
          ...state.shipmenTimeLine,
          timeLineData: payload,
        },
      };
    default:
      return state;
  }
});

export default subscriptionReducer;
