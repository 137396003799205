import produce from "immer";
import {
  FETCH_TUTORIAL_DATA,
  FETCH_TUTORIAL_DATA_ERROR,
  FETCH_TUTORIAL_DATA_LOADING,
} from "./actionTypes";

const initialState = {
  tutorialDataLoading: false,
  tutorialData: null,
};

const tutorialReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TUTORIAL_DATA_LOADING:
      return {
        ...state,
        tutorialDataLoading: payload,
      };
    case FETCH_TUTORIAL_DATA:
      return {
        ...state,
        tutorialData: payload,
      };
    case FETCH_TUTORIAL_DATA_ERROR:
      return {
        ...state,
        tutorialData: payload,
      };
    default:
      return state;
  }
});

export default tutorialReducer;
