export const SET_LOADING = "SET_LOADING";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_SITE_PRODUCTS = "SET_SITE_PRODUCTS";
export const SET_CART_DETAILS = "SET_CART_DETAILS   ";
export const REMOVE_CART = "REMOVE_CART";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_STATES = "SET_STATES";
export const SET_COUPON_CODE = "SET_COUPON_CODE";
export const SET_SHIPPING_ADDRESS = "SET_SHIPPING_ADDRESS";
export const SET_BILLING_ADDRESS = "SET_BILLING_ADDRESS";
export const SET_CARD_DETAILS = "SET_CARD_DETAILS";
export const SET_COUNTRY_ID = "SET_COUNTRY_ID";
export const SET_BILLING_AMOUNT_DETAILS = "SET_BILLING_AMOUNT_DETAILS";
export const SET_BILLING_AMOUNT_DETAILS_TEMP =
  "SET_BILLING_AMOUNT_DETAILS_TEMP";
export const SET_BILLING_COUPON_LIST = "SET_BILLING_COUPON_LIST";
export const SET_TOKEN_LOADING = "SET_TOKEN_LOADING";
export const SET_COMBO_DISCOUNT = "SET_COMBO_DISCOUNT";
export const SET_RENEWAL_TOTAL = "SET_RENEWAL_TOTAL";
export const SET_LOADING_CART_DETAILS = "SET_LOADING_CART_DETAILS";
export const SET_SAVING_SUBSCRIPTION = "SET_SAVING_SUBSCRIPTION";
export const SET_LOADING_COUPON = "SET_LOADING_COUPON";
export const SET_LOADING_USER_PRODUCT_DETAILS =
  "SET_LOADING_USER_PRODUCT_DETAILS";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_LOADING_COUNTRIES = "SET_LOADING_COUNTRIES";
export const SET_PUBLIC_KEY = "SET_PUBLIC_KEY";
export const CLEAR_SUBSCRIPTION_CONFIRMATION =
  "CLEAR_SUBSCRIPTION_CONFIRMATION";
export const CHECK_USER_HAVING_SAME_SUBSCRIPTION =
  "CHECK_USER_HAVING_SAME_SUBSCRIPTION";
export const CHECK_USER_HAVING_SAME_SUBSCRIPTION_DATA =
  "CHECK_USER_HAVING_SAME_SUBSCRIPTION_DATA";
export const SET_INTAKE_FORM = "SET_INTAKE_FORM";
export const SET_FEEDBACK = "SET_FEEDBACK";
export const SET_CHECKOUT_ERROR = "SET_CHECKOUT_ERROR";
export const SET_UPGRADED_PRODUCT = "SET_UPGRADED_PRODUCT";
export const SET_GUEST_USER = "SET_GUEST_USER";
export const SET_AUTH_OPTION = "SET_AUTH_OPTION";
export const SET_GUEST_USER_EMAIL = "SET_GUEST_USER_EMAIL";
export const SET_DISABLE_OTHER_STEP = "SET_DISABLE_OTHER_STEP";
export const SET_PURCHASE_OLD_VOLUME = "SET_PURCHASE_OLD_VOLUME";
