import produce from "immer";
// import { RESET } from "store/Common/actionTypes";
import {
  CHECK_USER_HAVING_SAME_SUBSCRIPTION,
  CHECK_USER_HAVING_SAME_SUBSCRIPTION_DATA,
  CLEAR_PRODUCT,
  CLEAR_SUBSCRIPTION_CONFIRMATION,
  REMOVE_CART,
  SET_AUTH_OPTION,
  SET_BILLING_ADDRESS,
  SET_BILLING_AMOUNT_DETAILS,
  SET_BILLING_AMOUNT_DETAILS_TEMP,
  SET_BILLING_COUPON_LIST,
  SET_CARD_DETAILS,
  SET_CART_DETAILS,
  SET_CHECKOUT_ERROR,
  SET_COMBO_DISCOUNT,
  SET_COUNTRIES,
  SET_COUNTRY_ID,
  SET_COUPON_CODE,
  SET_DISABLE_OTHER_STEP,
  SET_FEEDBACK,
  SET_GUEST_USER,
  SET_GUEST_USER_EMAIL,
  SET_INTAKE_FORM,
  SET_LOADING,
  SET_LOADING_CART_DETAILS,
  SET_LOADING_COUNTRIES,
  SET_LOADING_COUPON,
  SET_LOADING_STATES,
  SET_LOADING_USER_PRODUCT_DETAILS,
  SET_PRODUCT,
  SET_PUBLIC_KEY,
  SET_PURCHASE_OLD_VOLUME,
  SET_RENEWAL_TOTAL,
  SET_SAVING_SUBSCRIPTION,
  SET_SHIPPING_ADDRESS,
  SET_SITE_PRODUCTS,
  SET_STATES,
  SET_UPGRADED_PRODUCT,
} from "./actionTypes";

const initialState = {
  loading: false,
  loadingUserProductDetails: true,
  product: null,
  couponCode: "",
  publicKey: "",
  siteProducts: [],
  cartDetails: [],
  billingAmount: null,
  billingAmountTemp: null,
  billingCouponList: [],
  countryId: "",
  billingDetails: {},
  cardDetails: [],
  shippingDetails: {},
  countries: [],
  states: [],
  comboDiscount: 0,
  renewalTotal: [],
  loadingCartDetails: true,
  savingSubscription: false,
  loadingCoupon: false,
  loadingStates: false,
  loadingCountries: false,
  user_having_subscription: false,
  user_having_subscription_data: [],
  isError: false,
  upgradedProduct: null,
  isGuestUser: false, // true if user is unauthorize
  selectedAuthOption: 0, // 0: none, 1: guest, 2: login
  guestUserEmail: null,
  isDisableOtherStep: false,
  isPurchaseOldVolume: false,
};

const subscriptionConfirmationReducer = produce(
  (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case SET_LOADING_STATES:
        state.loadingStates = action.payload;
        break;
      case SET_LOADING_COUNTRIES:
        state.loadingCountries = action.payload;
        break;
      case CLEAR_PRODUCT:
        state.product = null;
        state.siteProducts = [];
        break;
      case SET_LOADING_USER_PRODUCT_DETAILS:
        state.loadingUserProductDetails = payload;
        break;
      case SET_LOADING_COUPON:
        state.loadingCoupon = payload;
        break;
      case SET_SAVING_SUBSCRIPTION:
        state.savingSubscription = payload;
        break;
      case SET_LOADING:
        state.loading = payload;
        break;
      case SET_PRODUCT:
        state.product = payload;
        break;
      case SET_SITE_PRODUCTS:
        state.siteProducts = payload;
        break;
      case SET_CART_DETAILS:
        state.cartDetails = payload;
        break;
      case SET_PUBLIC_KEY:
        state.publicKey = payload;
        break;
      case SET_BILLING_AMOUNT_DETAILS:
        state.billingAmount = payload;
        break;
      case SET_BILLING_AMOUNT_DETAILS_TEMP:
        state.billingAmountTemp = payload;
        break;
      case SET_BILLING_COUPON_LIST:
        state.billingCouponList = payload;
        break;
      case SET_CARD_DETAILS:
        state.cardDetails = payload;
        break;
      case SET_SHIPPING_ADDRESS:
        state.shippingDetails = payload;
        break;
      case SET_BILLING_ADDRESS:
        state.billingDetails = payload;
        break;
      case REMOVE_CART:
        state.cartDetails = state.cartDetails.filter(
          cartItem => cartItem.user_cart_id !== payload
        );
        break;
      case SET_COUNTRIES:
        state.countries = payload;
        break;
      case SET_STATES:
        state.states = payload;
        break;
      case SET_COUPON_CODE:
        state.couponCode = payload;
        break;
      case SET_COUNTRY_ID:
        state.countryId = payload;
        break;
      case SET_COMBO_DISCOUNT:
        state.comboDiscount = payload;
        break;
      case SET_RENEWAL_TOTAL:
        state.renewalTotal = payload;
        break;
      case SET_LOADING_CART_DETAILS:
        state.loadingCartDetails = payload;
        break;
      case CLEAR_SUBSCRIPTION_CONFIRMATION:
        Object.keys(initialState).forEach(key => {
          state[key] = initialState[key];
        });
        break;
      case CHECK_USER_HAVING_SAME_SUBSCRIPTION:
        state.user_having_subscription = payload;
        break;
      case CHECK_USER_HAVING_SAME_SUBSCRIPTION_DATA:
        state.user_having_subscription_data = payload;
        break;
      case SET_INTAKE_FORM:
        state.InTakeFromData = payload;
        break;
      case SET_FEEDBACK:
        state.feedback = payload;
        break;
      case SET_CHECKOUT_ERROR:
        state.isError = payload;
        break;
      case SET_UPGRADED_PRODUCT:
        state.upgradedProduct = payload;
        break;
      case SET_GUEST_USER:
        state.isGuestUser = payload;
        break;
      case SET_AUTH_OPTION:
        state.selectedAuthOption = payload;
        break;
      case SET_GUEST_USER_EMAIL:
        state.guestUserEmail = payload;
        break;
      case SET_DISABLE_OTHER_STEP:
        state.isDisableOtherStep = payload;
        break;
      case SET_PURCHASE_OLD_VOLUME:
        state.isPurchaseOldVolume = payload;
        break;
      default:
        return state;
    }
  }
);

export default subscriptionConfirmationReducer;
