export const SET_IS_AUTH = "SET_IS_AUTH";
export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT = "LOGOUT";
export const SET_CHATBOT_VISIBILITY = "SET_CHATBOT_VISIBILITY";

export const FETCH_USER_LOADING = "FETCH_USER_LOADING";
export const FETCH_USER = "FETCH_USER";

export const VERIFY_ACCOUNT_LOADING = "VERIFY_ACCOUNT_LOADING";
export const VERIFY_ACCOUNT_ = "VERIFY_ACCOUNT";

export const MAGIC_LINK_LOADING = "MAGIC_LINK_LOADING";

export const SET_INVITATION_DETAIL = "SET_INVITATION_DETAIL";
