import {
  SET_LOADING,
  SET_SUCCESS,
  SET_ERROR,
  SET_SECURITY_QUESTION_LOADING,
  SET_SECURITY_QUESTION,
} from "./actionTypes";
import produce from "immer";

const initialState = {
  loading: false,
  success: "",
  error: null,
  securityQuestionLoading: false,
  securityQuestion: null,
};

const loginReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_SUCCESS:
      return {
        ...state,
        success: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case SET_SECURITY_QUESTION_LOADING:
      return {
        ...state,
        securityQuestionLoading: payload,
      };
    case SET_SECURITY_QUESTION:
      return {
        ...state,
        securityQuestion: payload,
      };
    default:
      return state;
  }
});

export default loginReducer;
